import React, { useEffect, useState } from "react";
import Loader from "../components/loader/Loader";
import Navbar from "../components/navbar/Navbar";
import CareerHome from "../components/Careers/CareerHome";
import Footer from "../components/home/Footer";
import CareerBanner from "../components/Careers/CareerBanner";
import CareerForm from "../components/Careers/CareerForm";
import JobOpening from "../components/Careers/JobOpening";

export const Careers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedJob, setSelectedJob] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Function to handle the "Apply" button click
  const handleApplyClick = (jobTitle) => {
    setSelectedJob(jobTitle);
  };
  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Navbar />
          <CareerBanner />
          <CareerHome />
          <div className="border w-[90%] mx-auto my-10"></div>
          <div className="flex flex-col lg:flex-row items-start mx-5 lg:mx-10">
            <JobOpening onApplyClick={handleApplyClick} />
            <CareerForm selectedJob={selectedJob} />
          </div>
          <Footer />
        </>
      )}
    </>
  );
};
